<template>
  <div class="about p-5" id="dementie-information">
    <h1>Quickscan resultaten per vraag</h1>

    <br><br>

    <div class="d-flex flex-row justify-content-between align-items-start">
      <div class="p-2">
        <p class="result-information">Selecteer hieronder voor welke categorie u de Quickscan resultaten wilt zien</p>
        <div class="my-button-group">
          <b-button tabindex="-1" size="sm" class="justify-content-center"
            style="background-color: white; border: white">
            <b-button tabindex="0" autofocus aria-label="Selecteer de categorie voor Quickscan-resultaten"
              v-for="(questiongroup, index) in questionGroupIds" :key="index" variant="outline-primary"
              :class="{ active: selectedQuestionGroupIds.includes(questiongroup), 'button-space': true, 'icon-space': true }"
              :aria-pressed="false" @click="togglePhase(questiongroup)" class="flex-fill" role="button"
              style="margin-right: 60px;" :value="questiongroup" :name="questiongroup">
              <span>
                <font-awesome-icon v-if="selectedQuestionGroupIds.includes(questiongroup)" icon="check" size="1x"
                  class="custom-icon" /><br>
              </span>
              {{ questionGroups[index] }}
            </b-button>
          </b-button>
        </div>
      </div>

      <div class="p-2">
        <p class="result-information">Selecteer hieronder voor welke categorie u de Quickscan resultaten wilt zien</p>
        <div class="filter-space">
          <label for="vraag-id-filter"><b>Filter op periode: </b></label>
          <br>
          <select v-model="filterValueJaren" class="custom-select-form">
            <option value="365 dagen">Meest recente 365 dagen</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
          </select>
          <br>

          <label for="vraag-id-filter"><b>Filter op aantal gemeentes: </b></label>
          <br>
          <select v-model="filterValueGemeentes" class="custom-select-form" @input="resetFilterGemeentes">
            <option value="">Alle</option>
            <option value="1-5">1 tot 5 gemeentes</option>
            <option value="6-10">6 tot 10 gemeentes</option>
            <option value=">10">Meer dan 10 gemeentes</option>
          </select>

          <br>
          <label for="vraag-id-filter"><b>Filter op aantal 65+ mensen: </b></label>
          <br>
          <select v-model="filterValueAantal65Plus" class="custom-select-form" @input="resetFilterAantal65Plus">
            <option value="">Alle</option>
            <option value="<=100000">Minder dan 100.000 mensen</option>
            <option value="100000-150000">100.000 tot 150.000 mensen</option>
            <option value=">150000">Meer dan 150.000 mensen</option>
          </select>

          <br>
          <label for="vraag-id-filter"><b>Filter op aantal mensen met dementie: </b></label>
          <br>
          <select v-model="filterValueAantalDementie" class="custom-select-form" @input="resetFilterAantalDementie">
            <option value="">Alle</option>
            <option value="<=5000">Minder dan 5.000 mensen</option>
            <option value="5000-10000">5.000 tot 10.000 mensen</option>
            <option value=">10000">Meer dan 10.000 mensen</option>
          </select>
        </div>
      </div>
    </div>


    <br><br>


    <div class="row" v-for="(questionId) in selectedQuestionIdsFinal" :key="questionId">
      <div class="col-sm-12 down">
        <card :hovertext="''" :cardId="`Vraag ${questions[questionId - 37]}`"
          :x="[filteredData.filter(item => item.vraag_id === questionId).map(item => item.dementienetwerknaam)]"
          :y="[filteredData.filter(item => item.vraag_id === questionId).map(item => item.antwoord_id)]"
          :xtitle="'Regio naam'" :ytitle="'Aantal'" :tableHeight="300">
          <template slot="card-header">Vraag {{ questions[questionId - 37] }}</template>
          <!-- <template slot="card-header">Vraag {{filteredData.filter(item => item.vraag_id === questionId)}}</template> -->
          <div slot="card-body">
            De nummers in de y-as betekenen het volgende: 1 = helemaal niet gerealiseerd, 2 = grotendeels niet
            gerealiseerd, 3 =
            ongeveer de helft gerealiseerd, 4 = grotendeels gerealiseerd en 5 = volledig gerealiseerd.
            <stacked-bar-chart-colors-fixed v-if="load.graphOne" :xtitle="'Dementienetwerk'"
              :names="['Dementienetwerk']"
              :x="[filteredData.filter(item => item.vraag_id === questionId).map(item => item.dementienetwerknaam)]"
              :y="[filteredData.filter(item => item.vraag_id === questionId).map(item => item.antwoord_id)]"
              :showlegend="false" />
          </div>
        </card>
      </div>
    </div>
  </div>


</template>

<script>
import Card from '../components/Card'
import StackedBarChartColorsFixed from '../components/StackedBarChartColorsFixed'
import VueMeta from 'vue-meta'
import { mapGetters } from 'vuex'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Quickscan Resultaten',
  },
  name: 'QuickscanResultaten',
  components: { Card, StackedBarChartColorsFixed },
  data() {
    return {
      filterValueJaren: '365 dagen',
      filterValueGemeentes: '',
      filterValueAantal65Plus: '',
      filterValueAantalDementie: '',
      allData: [],
      questionIds: [],
      questions: [],
      questionGroupData: [],
      questionGroups: [],
      questionGroupIds: [],
      selectedQuestionGroupIds: [],
      selectedQuestionIds: [],
      selectedQuestionIdsFinal: [],
      questionIdsRemoval: [],
      questionIdStartRemovalIndex: [],
      load: {
        graphOne: false
      }
    }
  },
  computed: {
    ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear']),

    filteredByYearData() {
      if (this.filterValueJaren === '365 dagen') {
        return this.allData.filter((item) => item.jaar == '365 dagen')
      }
      else if (this.filterValueJaren === '2023') {
        return this.allData.filter((item) => item.jaar == '2023')
      }
      else if (this.filterValueJaren === '2022') {
        return this.allData.filter((item) => item.jaar == '2022')
      }
      else if (this.filterValueJaren === '2021') {
        return this.allData.filter((item) => item.jaar == '2021')
      }
      else if (this.filterValueJaren === '2020') {
        return this.allData.filter((item) => item.jaar == '2020')
      }
      else if (this.filterValueJaren === '2019') {
        return this.allData.filter((item) => item.jaar == '2019')
      }
      else {
        return this.allData.filter((item) => item.jaar == '365 dagen')
      }
    }
    ,
    filteredData() {
      if (this.filterValueGemeentes === '1-5') {
        return this.filteredByYearData.filter((item) => item.aantal_gemeentes <= 5)
      }
      else if (this.filterValueGemeentes === '6-10') {
        return this.filteredByYearData.filter((item) => (item.aantal_gemeentes > 5) && (item.aantal_gemeentes <= 10))
      }
      else if (this.filterValueGemeentes === '>10') {
        return this.filteredByYearData.filter((item) => item.aantal_gemeentes > 10)
      }
      if (this.filterValueAantal65Plus === '<=100000') {
        return this.filteredByYearData.filter((item) => item.aantal_65plus <= 100000)
      }
      else if (this.filterValueAantal65Plus === '100000-150000') {
        return this.filteredByYearData.filter((item) => (item.aantal_65plus > 100000) && (item.aantal_65plus <= 150000))
      }
      else if (this.filterValueAantal65Plus === '>150000') {
        return this.filteredByYearData.filter((item) => item.aantal_65plus > 150000)
      }
      if (this.filterValueAantalDementie === '<=5000') {
        return this.filteredByYearData.filter((item) => item.aantal_dementie <= 5000)
      }
      else if (this.filterValueAantalDementie === '5000-10000') {
        return this.filteredByYearData.filter((item) => (item.aantal_dementie > 5000) && (item.aantal_dementie <= 10000))
      }
      else if (this.filterValueAantalDementie === '>10000') {
        return this.filteredByYearData.filter((item) => item.aantal_dementie > 10000)
      }
      else {
        return this.filteredByYearData
      }
    },
  },
  methods: {
    loadData() {
      this.$http.get(`api/netwerkcoordinators/get_data_per_question/`).then(
        (response) => {
          this.allData = response.data
          this.questions = [...new Set(this.allData.map(d => (d.vraag)))]
          this.load.graphOne = true

          // sorting the data by "dementienetwerknaam"
          this.allData.sort((a, b) => {
            return a.dementienetwerknaam.localeCompare(b.dementienetwerknaam);
          });
        },
        (error) => {
          console.log(error);
        }
      ),
        this.$http.get(`api/koppeltabel_quickscan/`).then(
          (response) => {
            this.questionGroupData = response.data
            this.questionIds = Array.from({ length: this.questionGroupData.length }, (_, i) => i + 1)
            this.questionGroups = [...new Set(this.questionGroupData.map(d => d.vragengroep))]
            this.questionGroupIds = [...new Set(this.questionGroupData.map(d => d.vragengroep_id))]
          },
          (error) => {
            console.log(error);
          }
        )
    },
    resetFilterGemeentes() {
      this.filterValueAantal65Plus = '';
      this.filterValueAantalDementie = '';
    },
    resetFilterAantal65Plus() {
      this.filterValueGemeentes = '';
      this.filterValueAantalDementie = '';
    },
    resetFilterAantalDementie() {
      this.filterValueGemeentes = '';
      this.filterValueAantal65Plus = '';
    },
    togglePhase(questiongroup) {
      const index = this.selectedQuestionGroupIds.indexOf(questiongroup);

      if (index === -1) {
        // question group is not currently selected, so add it to the list
        this.selectedQuestionGroupIds.push(questiongroup);
        this.selectedQuestionIds = this.questionGroupData.filter(x => x.vragengroep_id == this.selectedQuestionGroupIds.slice(-1)).map(d => d.vraag_id);
        this.selectedQuestionIdsFinal.push.apply(this.selectedQuestionIdsFinal, this.selectedQuestionIds)
        this.selectedQuestionIdsFinal.sort((a, b) => a - b);
        this.selectedQuestionIds = [];
      } else {
        // question group is already selected, so remove it from the list
        this.selectedQuestionGroupIds.splice(index, 1);
        this.questionIdsRemoval = this.questionGroupData.filter(x => x.vragengroep_id == questiongroup).map(d => d.vraag_id);
        this.selectedQuestionIdsFinal = this.selectedQuestionIdsFinal.filter(item => !this.questionIdsRemoval.includes(item));
      }
    }
  },

  created() {
    this.loadData()
  }
}


</script>

<style scoped>
p {
  margin-left: 10px;
}

p.border-style {
  border-style: ridge;
}

.button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.my-button-group {
  margin-top: 2em;
}


.my-button-group .btn-outline-primary {
  outline: none;
  box-shadow: none;
  background-color: var(--color-2);
  border-color: var(--color-1);
  color: var(--color-1);
  font-weight: bold;
}

.my-button-group .btn-outline-primary:hover {
  background-color: var(--color-1);
  border-color: var(--color-1);
  color: var(--color-2);
}

.my-button-group .btn-outline-primary:focus {
  background-color: var(--color-14);
  border-color: var(--color-14);
  color: var(--color-2);
}

.my-button-group .btn-outline-primary.active {
  background-color: var(--color-1);
  border-color: var(--color-1);
  color: var(--color-2);
}

.my-button-group .btn-outline-primary {
  flex: 1;
  flex-basis: 120px;
}

.result-information {
  font-weight: bold;
  font-size: 18px;
  color: var(--color-10);
}

.button-space {
  margin: 10px;
}

.icon-space {
  display: flex;
  align-items: center;
}

.custom-icon {
  margin-right: 5px;
}

.custom-select-form {
  font-size: 16px;
  width: 50%;
  font-family: inherit;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 40px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}

.custom-select-form:hover {
  outline: none;
  box-shadow: none;
  background-color: var(--color-6);
  border-color: var(--color-6);
  font-weight: bold;
}

.custom-select-form:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--color-6);
  /*  border-color: var(--color-6);*/
  border: 2px var(--color-14) solid !important;
  font-weight: bold;
}

.filter-space {
  margin-top: 5em;
}
</style>
