import { render, staticRenderFns } from "./ZSD-NC-Resultaten-Quickscan.vue?vue&type=template&id=001f6a5e&scoped=true"
import script from "./ZSD-NC-Resultaten-Quickscan.vue?vue&type=script&lang=js"
export * from "./ZSD-NC-Resultaten-Quickscan.vue?vue&type=script&lang=js"
import style0 from "./ZSD-NC-Resultaten-Quickscan.vue?vue&type=style&index=0&id=001f6a5e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "001f6a5e",
  null
  
)

export default component.exports